import 'util/nightjs';
import './layout.scss';
import { createRoutesFromElements, Navigate, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastsContext from './toasts-context';
import AppDataContext from './app-data-context';
import Container from 'react-bootstrap/Container';
import CookieNotice from 'components/cookie-notice';
import { ErrorBoundary } from 'react-error-boundary';
import { List } from 'immutable';
import Navbar from 'components/navbar';
import Spinner from 'react-bootstrap/Spinner';
import styles from './layout.module.scss';
import { ToastContainer } from 'react-bootstrap';
const Home = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "homepage" */
'./home'));
const OurStory = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "our-story" */
'./our-story'));
const Services = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "services" */
'./services'));
const Reservations = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "reservations" */
'./reservations'));
const Gallery = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "gallery" */
'./gallery'));
export default function Layout({ context }) {
    const [toasts, setToasts] = useState(List());
    function push(props) {
        setToasts((v) => v.push(props));
    }
    return (React.createElement(AppDataContext.Provider, { value: context },
        React.createElement(ToastsContext.Provider, { value: { push } },
            React.createElement(Navbar, null),
            React.createElement("div", { className: styles.content },
                React.createElement(ErrorBoundary, { fallbackRender: ErrorFallback },
                    React.createElement(Routes, null, routes))),
            React.createElement(ToastContainer, { className: "position-fixed", position: "bottom-center" }, toasts.toSeq().map(({ props, headerProps, body }, i) => (React.createElement(Toast, { key: i, ...props, onClose: () => setToasts((v) => v.delete(i)) },
                headerProps && React.createElement(Toast.Header, { ...headerProps }, headerProps.children),
                body && React.createElement(Toast.Body, null, body))))),
            React.createElement(CookieNotice, null),
            React.createElement(Container, { as: "footer", className: "mt-4 mb-1", fluid: true },
                React.createElement("div", { className: "border-top border-bottom py-4 text-center" },
                    React.createElement("ul", { className: styles['footer-list'] },
                        React.createElement("li", null, "\u00A9 Hera Nails"),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/privacy.html" }, "Privacy")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/cookies.html" }, "Cookies")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "/policies/terms.html" }, "Terms"))))))));
}
const routes = [
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(Home, null)) }),
    React.createElement(Route, { key: "ourStory", caseSensitive: true, path: "/our-story", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(OurStory, null)) }),
    React.createElement(Route, { key: "services", caseSensitive: true, path: "/services", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(Services, null)) }),
    React.createElement(Route, { key: "reservations", caseSensitive: true, path: "/reservations", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(Reservations, null)) }),
    React.createElement(Route, { key: "gallery", caseSensitive: true, path: "/gallery", element: React.createElement(Suspense, { fallback: React.createElement(Container, { as: "main" },
                React.createElement("div", { className: "text-center w-100" },
                    React.createElement(Spinner, { animation: "border" }))) },
            React.createElement(Gallery, null)) }),
    React.createElement(Route, { key: "appointments", caseSensitive: true, path: "/appointments", element: React.createElement(Navigate, { to: "/reservations" }) }),
    React.createElement(Route, { key: "bookings", caseSensitive: true, path: "/bookings", element: React.createElement(Navigate, { to: "/reservations" }) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
// used by server to check early if a route exists or not to return an early 404.
export const appRoutes = routes.map((r) => createRoutesFromElements(r)).reduce((a, b) => a.concat(b));
function NotFound() {
    return (React.createElement(Container, { className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "404"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Page Not Found")),
        React.createElement("p", null,
            "Do you know where you're going to?",
            React.createElement("br", null),
            "Do you like the things that life is showing you?",
            React.createElement("br", null),
            "Where are you going to?",
            React.createElement("br", null),
            "Do you know",
            React.createElement("a", { className: "text-decoration-none", href: "https://www.youtube.com/watch?v=gsA-Xc6gWDE", rel: "external nofollow noopener" }, "?")),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
function ErrorFallback() {
    return (React.createElement(Container, { as: "main", className: "text-center" },
        React.createElement("h1", { className: "my-5" }, "500"),
        React.createElement("hr", null),
        React.createElement("p", { className: "mt-5" },
            React.createElement("b", null, "Internal Server Error")),
        React.createElement("p", null,
            "Server has encountered an unknown error.",
            React.createElement("br", null),
            "Please reach out to the site administrator for assistance."),
        React.createElement("a", { className: "text-decoration-none", href: "/" }, "Home")));
}
