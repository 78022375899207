import AppDataContext from 'app/app-data-context';
import { Set } from 'immutable';
import { useContext } from 'react';
export default function useUserGroups(...groups) {
    const appCtx = useContext(AppDataContext);
    if (!appCtx.user) {
        return {};
    }
    // TODO make a service call to /api/user or something to get the latest user data.
    let userGroups = Set(appCtx.user.groups);
    let results = {};
    groups.forEach((v) => (results[v] = userGroups.has(v)));
    return results;
}
