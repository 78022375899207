import PagesSection, { pageLinks } from './pages-section';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Icon from '@nguyengg/lambdajs/components/icon';
import LegalSection from './legal-section';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SmartNavLink from 'components/smart-nav-link';
import styles from './index.module.scss';
export default function AppNavbar() {
    const [shownCanvas, setShownCanvas] = useState(false);
    return (React.createElement(Navbar, { id: "navbar", className: styles.navbar, collapseOnSelect: true, expand: false, fixed: "top", variant: "dark", onSelect: () => setShownCanvas(false) },
        React.createElement(Container, { className: "d-none d-md-flex justify-content-center", fluid: true },
            React.createElement(Navbar.Brand, { className: styles.brand, href: "/" }, "Hera Nails & Spa")),
        React.createElement(Container, { className: "justify-content-between justify-content-md-center" },
            React.createElement(Nav, { className: "d-none d-md-flex flex-row" }, pageLinks.map(({ href, label }, i) => (React.createElement(SmartNavLink, { key: i, className: "px-3", href: href }, label)))),
            React.createElement(Navbar.Brand, { className: classNames('d-flex d-md-none', styles['brand']), href: "/" }, "Hera Nails & Spa"),
            React.createElement(Button, { "aria-controls": "navigation_sidebar", className: styles['toggler'], variant: "light", onClick: () => setShownCanvas(true) },
                React.createElement(Icon, { name: "list" }))),
        React.createElement(Navbar.Offcanvas, { id: "navigation_sidebar", className: styles['nav-sidebar'], placement: "end", restoreFocus: true, show: shownCanvas, onHide: () => setShownCanvas(false) },
            React.createElement(Offcanvas.Header, { className: "justify-content-end" },
                React.createElement(Button, { className: classNames('d-flex d-md-none', styles['toggler']), variant: "light", onClick: () => setShownCanvas(false) },
                    React.createElement(Icon, { name: "x-lg" }))),
            React.createElement(Offcanvas.Body, { className: "text-center" },
                React.createElement(Nav, null,
                    React.createElement(PagesSection, { header: Header }),
                    React.createElement("hr", null),
                    React.createElement(LegalSection, { header: Header }))))));
}
function Header({ children }) {
    return React.createElement("span", { className: "heading pb-2" }, children);
}
