import { NavLink, useLocation } from 'react-router-dom';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
/**
 * A Nav.Link that renders as react-router's NavLink if it's not active.
 *
 * The idea is that if the link is not active (i.e. if active is not explicitly specified, the link is treated as active
 * if the current location's pathname is the same as href) then react-router's NavLink is used to allow client-side
 * navigation similar to a single-page application (SPA). If the link is active, clicking on the link will reload the
 * page.
 */
export default function SmartNavLink({ active, children, href, ...props }) {
    const { pathname } = useLocation();
    return (React.createElement(Nav.Link, { active: active ?? pathname === href, as: pathname === href ? undefined : NavLink, href: href, to: href, ...props }, children));
}
