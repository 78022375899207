import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './index.module.scss';
import { useCookies } from 'react-cookie';
export default function CookieNotice() {
    const [cookies, setCookie] = useCookies(['cookies_2021-03-25']);
    const [show, setShow] = useState(() => !cookies['cookies_2021-03-25']);
    if (cookies['cookies_2021-03-25']) {
        return undefined;
    }
    return (React.createElement(Offcanvas, { autoFocus: false, backdrop: false, className: `border-light-subtle bg-light-subtle text-light-emphasis shadow-lg ${styles.body}`, enforceFocus: false, keyboard: false, placement: "bottom", scroll: true, show: show },
        React.createElement(Offcanvas.Body, { className: "text-center" },
            React.createElement("h6", null, "Cookie Notice"),
            "This site uses cookies to improve user experience pursuant to our",
            ' ',
            React.createElement("a", { className: "text-dark-emphasis fw-bold", href: "/policies/cookies.html", rel: "external nofollow noopener", target: "_blank" }, "cookie policy"),
            ".",
            React.createElement("br", { className: "d-none d-md-block" }),
            " By continuing to browse the site or clicking on \u201CAcknowledge\u201D, you consent to the storing of cookies on your device.",
            React.createElement("br", null),
            React.createElement(Button, { className: "mt-2", variant: "dark", onClick: () => {
                    setCookie('cookies_2021-03-25', 'true', {
                        maxAge: 31536000,
                        secure: true,
                        sameSite: 'lax',
                    });
                    setShow(false);
                } }, "Acknowledge"))));
}
