import React from 'react';
import Nav from 'react-bootstrap/Nav';
const policyLinks = [
    { href: '/policies/privacy.html', label: 'Privacy Policy' },
    { href: '/policies/cookies.html', label: 'Cookies Policy' },
    { href: '/policies/terms.html', label: 'Terms and Conditions' },
];
export default function LegalSection({ header: Header, }) {
    return (React.createElement("div", null,
        React.createElement(Header, null, "Legal"),
        policyLinks.map(({ href, label }, i) => (React.createElement(Nav.Link, { key: i, href: href }, label)))));
}
