import React, { useContext } from 'react';
import AppDataContext from 'app/app-data-context';
import Nav from 'react-bootstrap/Nav';
import SmartNavLink from 'components/smart-nav-link';
import useUserGroups from 'hooks/use-user-groups';
export const pageLinks = [
    { href: '/', label: 'Home' },
    // { href: '/our-story', label: 'Our Story' },
    { href: '/services', label: 'Services' },
    { href: '/reservations', label: 'Reservations' },
    { href: '/gallery', label: 'Gallery' },
];
export default function PagesSection({ header: Header, }) {
    const { intranetHost } = useContext(AppDataContext);
    const { can_view_appointment, can_view_gift_card, can_view_payment } = useUserGroups('can_view_appointment', 'can_view_gift_card', 'can_view_payment');
    return (React.createElement("div", null,
        React.createElement(Header, null, "Pages"),
        pageLinks.map(({ href, label }, i) => (React.createElement(SmartNavLink, { key: i, href: href }, label))),
        !!intranetHost && (can_view_appointment || can_view_gift_card || can_view_payment) && (React.createElement(React.Fragment, null,
            React.createElement("hr", null),
            React.createElement(Header, null, "Intranet"),
            React.createElement(Nav.Link, { href: `//${intranetHost}/appointments` }, "Appointments"),
            React.createElement(Nav.Link, { href: `//${intranetHost}/gift-cards` }, "Gift Cards"),
            React.createElement(Nav.Link, { href: `//${intranetHost}/payments` }, "Payments")))));
}
